<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialogVisible" title="错误信息列表" width="840px" @closed="handleClosed">
    <el-table :data="accountList" border style="width: 100%;">
      <el-table-column prop="distributorCode" header-align="center" show-overflow-tooltip align="center" label="经销商编号" />
      <el-table-column prop="rebateType" header-align="center" show-overflow-tooltip align="center" label="返利类型" />

      <el-table-column prop="money" header-align="center" align="center" show-overflow-tooltip label="返利金额（单位分）" />
      <el-table-column label="过期时间" :formatter="r => { return new Date(r.expireTime).format('yyyy/MM/dd'); }" width="100" />
      <el-table-column prop="info" header-align="center" align="center" label="备注"  width="100" />
      <el-table-column prop="errorInfo" header-align="center" align="center" label="错误信息" width="250" show-overflow-tooltip />
    </el-table>
  </el-dialog>
</template>

<script>
import initData from "@/mixins/initData";
export default {
  mixins: [initData],
  data() {
    return {
      dialogVisible: false,
      accountList: []
    };
  },
  methods: {
    handleClosed() {
      this.dialogVisible = false;
    },
    resetForm(form) {
      this.dialogVisible = true;
      this.accountList = form || [];
      if (this.accountList.length > 10) this.accountList.length = 10;
    }
  }
};
</script>